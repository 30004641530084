import React from "react"
import Cookie from "../models/Cookie"


const locale = {
  t: (text: string) => {
    const sentence = locale.sentences.find(d => d.en === text)
    return sentence ? sentence[Cookie.getLanguage()] : text
  },
  sentences: [
    // Login
    {
      en: "What's your nickname?",
      ru: "Ваш ник?"
    },
    {
      en: "Nickname",
      ru: "Ник"
    },
    {
      en: "Go",
      ru: "Вперед!"
    },
    {
      en: "Choose your language",
      ru: "Выберите ваш язык"
    },
    // Home
    {
      en: "Home",
      ru: "Главная"
    },
    {
      en: "Welcome",
      ru: "Добро пожаловать"
    },
    {
      en: "Your balance",
      ru: "Ваш баланс"
    },
    {
      en: "Create game",
      ru: "Создать игру"
    },
    {
      en: "Logout",
      ru: "Выйти"
    },
    // Find game
    {
      en: "Room name",
      ru: "Наим. комн."
    },
    {
      en: "Mode",
      ru: "Режим"
    },
    {
      en: "Players",
      ru: "Игроки"
    },
    {
      en: "Bet",
      ru: "Ставка"
    },
    {
      en: "Join",
      ru: "Присоединиться"
    },
    {
      en: "Return",
      ru: "Вернуться"
    },
    {
      en: "no rooms",
      ru: "Нет комнат"
    },
    {
      en: "Error",
      ru: "Ошибка"
    },
    {
      en: "Close",
      ru: "Закрыть"
    },
    {
      en: "Are you sure? Bet amount: ",
      ru: "Вы уверены? Сумма ставки: "
    },
    {
      en: "Something went wrong",
      ru: "Что то пошло не так"
    },
    // Create game
    {
      en: "Create game",
      ru: "Создать игру"
    },
    {
      en: "Room name",
      ru: "Наим. комн."
    },
    {
      en: "Players:",
      ru: "Игроки:"
    },
    {
      en: "Mode:",
      ru: "Режим:"
    },
    {
      en: "Fast",
      ru: "Быстрый"
    },
    {
      en: "Slow",
      ru: "Медленный"
    },
    {
      en: "All",
      ru: "Все"
    },
    {
      en: "Bet amount",
      ru: "Сумма ставки"
    },
    {
      en: "Your balance",
      ru: "Ваш баланс"
    },
    {
      en: "Description",
      ru: "Сообщение"
    },
    {
      en: "Create room",
      ru: "Создать комнату"
    },
    {
      en: "go back",
      ru: "назад"
    },
    // Waiting for players
    {
      en: "Waiting for players",
      ru: "Ожидание игроков"
    },
    {
      en: "Waiting for players",
      ru: "Ожидание игроков"
    },
    {
      en: "Room has been closed",
      ru: "Комната закрыта"
    },
    // Game
    {
      en: "Loading",
      ru: "Загрузка"
    },
    {
      en: "Room is not initialized",
      ru: "Комната не инициализирована"
    },
    {
      en: "You win",
      ru: "Вы победили"
    },
    {
      en: "You lose",
      ru: "Вы проиграли"
    },
    {
      en: "Right word",
      ru: "Правильное слово"
    },
    {
      en: "Your balance",
      ru: "Ваш баланс"
    },
    {
      en: "Draw",
      ru: "Ничья"
    },
    {
      en: "Fund",
      ru: "Фонд"
    },
    {
      en: "My bet",
      ru: "Моя ставка"
    },
    {
      en: "I am ready",
      ru: "Я готов"
    },
    {
      en: "ready",
      ru: "готов"
    },
    {
      en: "waiting",
      ru: "ожидание"
    },
    {
      en: "Leave",
      ru: "Покинуть"
    },
    {
      en: "Draw",
      ru: "Ничья"
    },
    // Backend exceptions
    {
      en: "Can't start bet",
      ru: "Невожможно начать ставку"
    },
    {
      en: "The room doesn't exists",
      ru: "Комната не существует"
    },
    {
      en: "Player don't exists",
      ru: "Несуществует такого игрока"
    },
    {
      en: "Bet amount cannot be lesser than 0",
      ru: "Сумма ставки не может быть меньше нуля"
    },
    {
      en: "You don't have enought money",
      ru: "Недостаточно средств"
    },
    {
      en: "The room doesn't accept new players anymore",
      ru: "Комната больше не принимает новых игроков"
    },
    {
      en: "You have already joined to the room",
      ru: "Вы уже подключились к комнате"
    },
    {
      en: "The room isnt ready to be started",
      ru: "Комната не готова к начале игры"
    },
    {
      en: "Key should be symbol",
      ru: "Неверный ввод клавиши"
    },
  ]
}

const LocaleContext = React.createContext(locale)
export default LocaleContext